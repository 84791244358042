import React, { useState, useContext, useEffect } from "react"
import { Form, Input, Button, message, Spin } from "antd"
import "./Login.scss"
import useFirebase from "../../firebase/useFirebase"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import getName from "../../helper/getName"
import { State } from "../../context/State"

interface Props {
  onLogin: () => void
}

const FormComponent = ({ onLogin }: Props) => {
  const firebase = useFirebase()
  const [isSignUp, setIsSignUp] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = (values: object) => {
    const { email, password, name } = values as {
      email: string
      password: string
      name?: string
    }
    console.log(email, password, name)
    if (!firebase) {
      if (isSignUp) {
        setIsLoading(false)
        message.error("Sorry, we were unable to sign you up at this time.")
      } else {
        setIsLoading(false)
        message.error("Sorry, we were unable to log you in at this time.")
      }
      return
    }

    setIsLoading(true)
    if (isSignUp) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .catch(error => {
          setIsLoading(false)
          message.error("Error: " + error.message)
        })
        .then(async credential => {
          if (credential) {
            const usersRef = firebase
              .firestore()
              .collection("users")
              .doc(credential.user?.uid)
            await usersRef.set({
              name,
              email,
              uid: credential.user?.uid,
            })
            onLogin()
          }
        })
    } else {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .catch(error => {
          setIsLoading(false)
          message.error("Error: " + error.message)
        })
        .then(async credential => {
          if (credential) {
            const usersRef = firebase
              .firestore()
              .collection("users")
              .doc(credential.user?.uid)
            const document = await usersRef.get()
            const data = document.data()
            if (document.exists && data) {
              if (!data.uid || data.uid === "") {
                await usersRef.update({
                  uid: credential.user?.uid,
                })
              }
            }
            setIsLoading(false)
            onLogin()
          }
        })
    }
  }

  const onFinishFailed = (errorInfo: object) => {
    console.log("Failed:", errorInfo)
  }
  return (
    <Form
      className="login__form"
      name="login"
      onFinish={values => onFinish(values)}
      onFinishFailed={onFinishFailed}
    >
      <h1>{isSignUp ? "Sign Up" : "Sign In"}</h1>
      <p>
        {isSignUp
          ? "New here? Thanks for stopping by!"
          : "Welcome back! It's good to have you."}
      </p>
      {isSignUp ? (
        <Form.Item
          name="name"
          className="login__form-item"
          rules={[{ required: true, message: "Please input your name." }]}
        >
          <Input
            placeholder="Full Name"
            className="login__input login__input--lastname"
          />
        </Form.Item>
      ) : (
        <></>
      )}
      <Form.Item
        name="email"
        className="login__form-item"
        rules={[{ required: true, message: "Please input your email." }]}
      >
        <Input placeholder="Email" className="login__input" />
      </Form.Item>
      <Form.Item
        name="password"
        className="login__form-item"
        rules={[{ required: true, message: "Please input a password." }]}
      >
        <Input.Password placeholder="Password" className="login__input" />
      </Form.Item>
      <div className="login__buttons">
        <Button loading={isLoading} type="primary" htmlType="submit">
          {isSignUp ? "Sign Up" : "Sign In"}
        </Button>
        <Button type="link" onClick={() => setIsSignUp(!isSignUp)}>
          {isSignUp ? "Sign In" : "Sign Up"}
        </Button>
      </div>
    </Form>
  )
}

const LoginForm = ({ onLogin }: Props) => {
  const firebase = useFirebase()
  const state: State = useContext<any>(GlobalStateContext)

  const onSignOut = () => {
    if (!firebase) return
    message.loading("Signing you out...", 1)
    firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
        message.error(error.message)
      })
  }

  return (
    <div className="login">
      {state.user !== undefined ? (
        state.user ? (
          <div className='className="login__form"'>
            <h1>
              {state.user.name
                ? `Welcome back, ${getName(state.user.name).first}!`
                : "Welcome back!"}
            </h1>
            <p>
              It looks like you're already signed in. Click below to head to
              your dashboard!
            </p>
            <div className="login__buttons">
              <Button type="primary" onClick={() => onLogin()}>
                Go to Dashboard
              </Button>
              <Button type="link" onClick={onSignOut}>
                Not you? Sign out
              </Button>
            </div>
          </div>
        ) : (
          <FormComponent onLogin={onLogin} />
        )
      ) : (
        <Spin size="large" />
      )}
    </div>
  )
}

export default LoginForm
