import React, { useState } from "react"
import HomeLayout from "../../components/home/HomeLayout"
import LoginForm from "../../components/login/LoginForm"
import { navigate } from "gatsby"

const Login = () => {
  const [fadeOut, setFadeOut] = useState(false)
  const [fadeOutAll, setFadeOutAll] = useState(false)

  const onLogin = () => {
    setFadeOut(true)
    setFadeOutAll(true)
    setTimeout(() => navigate("/dashboard"), 250)
  }

  return (
    <HomeLayout fadeOut={fadeOut} fadeOutAll={fadeOutAll} page="login">
      <LoginForm onLogin={onLogin} />
    </HomeLayout>
  )
}

export default Login
